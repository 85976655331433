const colorMap : Map<number, string> = new Map([
    [-10, "rgb(105, 212, 255)"],
    [-9, "rgb(105, 212, 255)"],
    [-8, "rgb(22, 141, 238)"],
    [-7, "rgb(22, 141, 238)"],
    [-6, "rgb(22, 69, 238)"],
    [-5, "rgb(22, 69, 238)"],
    [-4, "rgb(2, 40, 177)"],
    [-3, "rgb(2, 40, 177)"],
    [-2, "rgb(10,26,86)"],
    [-1, "rgb(10,26,86)"],
    [0, "rgb(72,22,122)"],
    [1, "rgb(82,0,99)"],
    [2, "rgb(82,0,99)"],
    [3, "rgb(145, 0, 73)"],
    [4, "rgb(145, 0, 73)"],
    [5, "rgb(202, 57, 0)"],
    [6, "rgb(202, 57, 0)"],
    [7, "rgb(255,124,2)"],
    [8, "rgb(255,124,2)"],
    [9, "rgb(255,217,0)"],
    [10, "rgb(255,217,0)"],
])

const transparentColorMap : Map<number, string> = new Map([
    [-10, "rgba(105, 212, 255, 0.3)"],
    [-9, "rgba(105, 212, 255, 0.3)"],
    [-8, "rgba(22, 141, 238, 0.3)"],
    [-7, "rgba(22, 141, 238, 0.3)"],
    [-6, "rgba(22, 69, 238, 0.3)"],
    [-5, "rgba(22, 69, 238, 0.3)"],
    [-4, "rgba(2, 40, 177, 0.3)"],
    [-3, "rgba(2, 40, 177, 0.3)"],
    [-2, "rgba(10,26,86, 0.3)"],
    [-1, "rgba(10,26,86, 0.3)"],
    [0, "rgba(72,22,122, 0.3)"],
    [1, "rgba(82,0,99, 0.3)"],
    [2, "rgba(82,0,99, 0.3)"],
    [3, "rgba(145, 0, 73, 0.3)"],
    [4, "rgba(145, 0, 73, 0.3)"],
    [5, "rgba(202, 57, 0, 0.3)"],
    [6, "rgba(202, 57, 0, 0.3)"],
    [7, "rgba(255,124,2, 0.3)"],
    [8, "rgba(255,124,2, 0.3)"],
    [9, "rgba(255,217,0, 0.3)"],
    [10, "rgba(255,217,0, 0.3)"],
])


export { colorMap, transparentColorMap }